import { APIS, BASE_URL } from "../constants/appConstants";
import { fetchPost } from "../utils/fetchPost";
async function getAllMoments(data) {
  return await fetchPost(BASE_URL + APIS.GET_ALL_MOMENTS, "POST", data);
}

async function shareMoment(data) {
  return await fetchPost(BASE_URL + APIS.POST_MOMENT_SHARE, "POST", data);
}

async function likeMoment(data) {
  return await fetchPost(BASE_URL + APIS.POST_MOMENT_LIKE, "POST", data);
}

const momentServices = {
  getAllMoments,
  shareMoment,
  likeMoment,
};

export default momentServices;
