import { useContext, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { auth, firebase } from "../../firebase/config";
import UserServices from "../../services/userServices";
import { allowedUnverifiedAccounts } from "../../constants/appConstants";
import { updateContext } from "../../utils/appUtils";
const provider = new firebase.auth.GoogleAuthProvider();

const USER_MEGS = {
  NUMBER_NOT_FORMATTED: {
    success: false,
    msg: "Please enter a valid number.",
  },
  WRONG_EMAIL_PASSWORD: {
    success: false,
    msg: "Wrong email or password",
  },
  OTP_SENT_SUCCESS: {
    success: true,
    msg: "Otp has been sent please check your mobile.",
  },
  OTP_SENT_FAILURE: {
    success: false,
    msg: "Please try again.",
  },
  OTP_VERIFY_SUCCESS: {
    success: true,
    msg: "Otp verified successfully.",
  },
  OTP_VERIFY_FAILURE: {
    success: false,
    msg: "Incorrect otp.",
  },
  NOT_AUTHORIZED: {
    success: false,
    msg: "You aren't authorized to login",
  },
  OTP_LENGTH: {
    success: false,
    msg: "Please enter 6 digits.",
  },
  EMAIL_NOT_VERIFIED: {
    success: false,
    msg: "Please click on the link that has been sent to your email account to verify your email.",
  },
};

const Login = () => {
  const [showOtp] = useState(false);
  const [userMsg, setUserMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [googleLoginLoading, setGoogleLoginLoading] = useState(false);

  const { setUser, setValueByKey } = useContext(AppContext);
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUserMsg(null);
    var formData = new FormData(e.target);
    const email = formData.get("email");
    const password = formData.get("password");

    setLoading(true);

    try {
      const isUser = await UserServices.checkUser({ checkValue: email });
      if (!isUser?.userFound) {
        setUserMsg(USER_MEGS.WRONG_EMAIL_PASSWORD);
        setLoading(false);
        return;
      }
    } catch (err) {}

    try {
      const firebaseAuthRes = await auth?.signInWithEmailAndPassword(
        email,
        password
      );

      const token = await firebaseAuthRes?.user.getIdToken(true);

      console.log(firebaseAuthRes, firebaseAuthRes?.user?.email, token);

      if (
        !allowedUnverifiedAccounts?.includes(firebaseAuthRes?.user?.email) &&
        !firebaseAuthRes?.user?.emailVerified
      ) {
        return setUserMsg(USER_MEGS.EMAIL_NOT_VERIFIED);
      }

      const userDetails = await UserServices.fetchUserDetails({
        checkValue: email,
      });
      history.push("/");
      setUser(firebaseAuthRes?.user);
      updateContext(setValueByKey, userDetails);
      setValueByKey("token", token);
    } catch (err) {
      console.log(err?.code, err);
      if (err?.code === "auth/wrong-password") {
        setUserMsg(USER_MEGS.WRONG_EMAIL_PASSWORD);
        setLoading(false);
      }
    } finally {
      setLoading(false);
    }
  };

  const signIn = () => {
    setGoogleLoginLoading(true);
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (data) => {
        const userData = data?.user?._delegate;

        try {
          const isUser = await UserServices.checkUser({
            checkValue: userData?.email,
          });
          if (!isUser?.userFound) {
            setUserMsg(USER_MEGS.WRONG_EMAIL_PASSWORD);
            setGoogleLoginLoading(false);
            return;
          }
        } catch (err) {}

        try {
          const userDetails = await UserServices.fetchUserDetails({
            checkValue: userData?.email,
          });
          history.push("/");
          setUser(userData);
          updateContext(setValueByKey, userDetails);
        } catch (err) {
          console.log(err?.code, err);
          if (err?.code === "auth/wrong-password") {
            setUserMsg(USER_MEGS.WRONG_EMAIL_PASSWORD);
            setGoogleLoginLoading(false);
          }
        } finally {
          setGoogleLoginLoading(false);
        }
      })
      .catch((error) => alert(error.message));
  };
  return (
    <div className="container">
      <div id="re-c"></div>

      <div className="row">
        <div className="col-9 col-md-4 mx-auto my-4">
          <h4 className="text-center mb-3">Login to Class Proxima</h4>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="form-label">Email</label>
              <input
                disabled={googleLoginLoading || loading}
                required
                type="email"
                name="email"
                className="form-control shadow-none"
                readOnly={showOtp}
              />
            </div>
            <div className={`mb-${showOtp ? "4" : "1"}`}>
              <label className="form-label">Password</label>
              <input
                disabled={googleLoginLoading || loading}
                required
                type="password"
                name="password"
                className="form-control shadow-none"
                readOnly={showOtp}
              />
            </div>

            <p
              style={{
                visibility: userMsg ? "visible" : "hidden",
              }}
              className={`m-0 text-${
                userMsg?.success ? "success" : "danger"
              } mb-2`}
            >
              {userMsg ? userMsg?.msg : "Hello"}
            </p>
            <button
              disabled={googleLoginLoading || loading}
              type="submit"
              className="btn w-100 btn-outline-primary shadow-none"
            >
              {loading ? (
                <>
                  <span
                    class="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Loading...</span>
                </>
              ) : (
                "Login"
              )}
            </button>
          </form>
          <div className="d-flex mt-3 justify-content-between">
            <Link className="fw-500" to="/forgot-password">
              Forgot Password?
            </Link>
            <div className="d-flex flex-column flex-md-row">
              <p className="m-0 me-2 ff-tw">Not a member?</p>
              <Link className="fw-500" to="/register">
                Register now
              </Link>
            </div>
          </div>

          {!showOtp && (
            <div className="my-5 border-top py-5">
              <div className="h-100 d-flex align-items-center justify-content-center">
                <button
                  disabled={googleLoginLoading || loading}
                  className="w-100 btn btn-dark border"
                  onClick={signIn}
                >
                  {googleLoginLoading ? (
                    <>
                      <span
                        class="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only">Please wait...</span>
                    </>
                  ) : (
                    <div className="d-flex justify-content-around">
                      <i className="bi bi-google"></i>
                      <p className="m-0 w-75 ff-tw fs-16 fw-500 text-center">
                        Sign In With Google
                      </p>
                    </div>
                  )}
                </button>
              </div>

              <div className="h-100 d-flex align-items-center justify-content-center mt-4">
                <Link
                  disabled={googleLoginLoading || loading}
                  className="w-100 btn btn-dark border d-flex justify-content-around"
                  // to="/mobile-login"
                >
                  <i class="bi bi-phone"></i>
                  <p className="m-0 w-75 ff-tw fs-16 fw-500 text-center">
                    Login using Mobile
                  </p>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { Login };
