import { useContext, useEffect, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useCallback } from "react";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import { getQueryParameters } from "../../utils/appUtils";
import "./shorts-ui.css";
import VideoCardShorts from "../../components/video-card-shorts/video-card-shorts";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import CircleServices from "../../services/circleServices";
import MomentServices from "../../services/momentServices";
import toast from "react-hot-toast";
import { AppContext } from "../../context/AppContext";
import Flatpickr from "react-flatpickr";
import Tooltip from "@mui/material/Tooltip";

const LIMIT = 10;

const FloatingButtons = ({
  isAbsolute,
  handleMomentSharing,
  handleLikeUnlike,
  isLiked,
  likesCount,
}) => {
  return (
    <>
      {/* <div
        className="action-btn-box d-flex align-items-center justify-content-center pointer"
        onClick={(e) => {}}
      >
        <i
          class={`bi bi-chat-text fs-24 fw-300 ${
            isAbsolute ? "text-white" : ""
          }`}
        ></i>
      </div> */}

      <div
        className="action-btn-box d-flex align-items-center justify-content-center pointer"
        onClick={(e) => {
          handleMomentSharing();
        }}
      >
        <i
          class={`bi bi-share fs-24 fw-300 ${isAbsolute ? "text-white" : ""}`}
        ></i>
      </div>
      <div>
        <div
          className={`action-btn-box position-relative heartAnimation ${
            isLiked ? "active" : ""
          }`}
          onClick={(e) => {
            handleLikeUnlike();
          }}
        >
          {likesCount > 0 && (
            <div className="position-absolute w-100 h-100 d-flex align-items-end justify-content-center pb-2">
              <p
                className={`m-0 ff-tw fw-70 text-${
                  isAbsolute ? "white" : "dark"
                }`}
              >
                {likesCount}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const ShortsUI = () => {
  //for feeds
  const [feeds, setFeeds] = useState(null);
  const [offset, setOffset] = useState(0);

  //For feed sharing
  const [currSharedMoment, setCurrSharedMoment] = useState(null);
  const [currMomentCircle, setCurrMomentCircle] = useState(null);

  //Circles List for a school
  const [circlesList, setCirclesList] = useState([]);
  const [circlesListObject, setCirclesListObject] = useState({});

  const [endReached, setEndReached] = useState(false);

  const [loading, setLoading] = useState(false);
  const [feedLoading, setFeedLoading] = useState(false);

  const { search } = useLocation();
  const { school_id } = getQueryParameters(search);
  const { height } = useWindowDimensions();
  const feedContainerRef = useRef();
  const { userDbData, isAdmin } = useContext(AppContext);

  const [filter, setFilter] = useState({
    date: null,
  });

  //used to show the moments by default it will be school_id available in the url
  const [currSchoolId, setCurrentSchoolId] = useState(school_id);

  const [showFiltersModal, setShowFilterModal] = useState(false);

  const [forcedRefresh, setForcedRefresh] = useState(null);

  const FRAME_HEIGHT = height * 0.92;
  const animatedComponents = makeAnimated();

  //set school id for non admin roles
  useEffect(() => {
    if (userDbData && !isAdmin) {
      let school_id = userDbData?.school_id?._id;
      if (!school_id) {
        school_id = userDbData?.school_id?.[0]?._id;
      }
      setCurrentSchoolId(school_id);
    }
  }, [userDbData]);

  useEffect(() => {
    if (!userDbData || !currSchoolId) return;

    CircleServices.getAllCircles({
      query: `?school_id=${currSchoolId}`,
    }).then((res) => {
      const list = res?.data?.data;
      setCirclesList(list);
      const listObj = {};
      list?.forEach((circle) => {
        listObj[circle?._id] = circle;
      });
      setCirclesListObject(listObj);
    });
  }, [userDbData, currSchoolId]);

  const handleScrollDown = (e) => {
    feedContainerRef.current.scrollTo({
      top: feedContainerRef.current.scrollTop + FRAME_HEIGHT,
      behavior: "smooth",
    });
  };
  const handleScrollUp = () => {
    feedContainerRef.current.scrollTo({
      top: feedContainerRef.current.scrollTop - FRAME_HEIGHT,
      behavior: "smooth",
    });
  };

  async function loadData() {
    if (endReached || !userDbData) return;

    //set feed as [] to show the select school message
    if (isAdmin && !currSchoolId) {
      setFeeds([]);
      return;
    }
    if (feeds === null) {
      setFeedLoading(true);
    }

    MomentServices.getAllMoments({
      school_id: currSchoolId,
      share_with: userDbData?.circles,
      skip: offset,
      limit: LIMIT,
      date: filter?.date,
    })
      .then((res) => {
        if (res?.results?.length === 0) {
          setEndReached(true);

          if (feeds === null || offset === 0) {
            setFeeds([]);
          }
          return;
        }
        if (!feeds || offset === 0) {
          setFeeds(res?.results);
        } else {
          if (feeds === null) return;
          setFeeds((prev) => {
            return prev?.concat(res?.results);
          });
        }
      })
      .catch((err) => {})
      .finally(() => {
        setFeedLoading(false);
      });
  }

  //Handling play and pause
  const observeVideos = () => {
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry?.target?.play();
        } else {
          entry?.target?.pause();
        }
      });
    };
    const options = { threshold: 0.5 };
    const observer = new IntersectionObserver(callback, options);
    const videos = document.querySelectorAll(".feed-video");
    if (videos?.length === 0) return;
    videos?.forEach((vid) => {
      observer.observe(vid);
    });
  };

  const handleMomentSharing = (item) => {
    setCurrSharedMoment(item);
    setCurrMomentCircle(
      item?.share_with?.map((circle) => ({
        value: circle?._id,
        label: circle?.circle_name,
      }))
    );
  };

  const closeMomentShareModal = () => {
    setCurrSharedMoment(null);
    setLoading(false);
    setCurrMomentCircle(null);
  };

  const validateIfAllSame = (selectedCirclesServers, currSharedMoment) => {
    const momentSharedWith = currSharedMoment?.share_with?.map(
      (circle) => circle?._id
    );
    if (selectedCirclesServers?.length !== momentSharedWith?.length)
      return false;
    return selectedCirclesServers?.every((circle) =>
      momentSharedWith?.includes(circle)
    );
  };

  const saveMomentShareScope = () => {
    const selectedCirclesServers = currMomentCircle?.map((item) => item?.value);
    const selectedCirclesUI = selectedCirclesServers?.map(
      (circle) => circlesListObject[circle]
    );

    if (validateIfAllSame(selectedCirclesServers, currSharedMoment)) {
      toast.success("Shared 🎉");
      setCurrSharedMoment(null);
      setCurrMomentCircle(null);
      return;
    }

    setLoading(true);

    const toastId = toast.loading("Sharing...");

    MomentServices.shareMoment({
      circle_ids: selectedCirclesServers,
      school_id: currSharedMoment?.school_id,
      _id: currSharedMoment?._id,
    })
      .then((res) => {
        if (res?.status === 200) {
          setFeeds((prev) =>
            prev?.map((feed) =>
              feed?._id === currSharedMoment?._id
                ? {
                    ...feed,
                    share_with: selectedCirclesUI,
                  }
                : feed
            )
          );
          toast.success("Shared 🎉", {
            id: toastId,
          });
          setCurrSharedMoment(null);
          setCurrMomentCircle(null);
        } else {
          toast.error("Couldn't share moment.", {
            id: toastId,
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    observeVideos();
  }, [feeds]);

  useEffect(() => {
    if (currSchoolId === school_id) return;
    setOffset(0);
    setFeeds(null);
    setEndReached(false);
    setCurrentSchoolId(school_id);
    setFilter({
      date: null,
    });
  }, [school_id]);

  useEffect(() => {
    if (feeds && feeds?.length % LIMIT !== 0) return;
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, userDbData, currSchoolId, endReached, forcedRefresh]);

  //load more videos dynamically
  const observer = useRef();
  const videoRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setOffset((prev) => prev + LIMIT);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  const handleLikeUnlike = (item) => {
    MomentServices.likeMoment({
      school_id: currSchoolId,
      user_id: userDbData?._id,
      _id: item?._id,
    }).then((res) => {
      const feedData = res?.data;
      setFeeds((prev) =>
        prev?.map((feed) => (feed?._id === feedData?._id ? feedData : feed))
      );
    });
  };

  const handleFilterModalClose = () => {
    setShowFilterModal(false);
    setOffset(0);
    setEndReached(false);
    setFeeds(null);
    //must for reloading the data
    setForcedRefresh(Math.random());
  };

  return (
    <>
      <Modal
        show={currSharedMoment}
        size="lg"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body
          className="p-0"
          style={{
            height: 400,
          }}
        >
          <div className="p-3">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <p className="m-0 ff-tw">Share moment</p>
              <div>
                <i
                  class="bi bi-check-circle text-success fs-20 pointer"
                  onClick={() => {
                    saveMomentShareScope();
                  }}
                ></i>

                <i
                  class="bi bi-x-lg ms-3 fs-18 pointer"
                  onClick={() => {
                    closeMomentShareModal();
                  }}
                ></i>
              </div>
            </div>

            <Select
              options={circlesList?.map((item) => ({
                value: item?._id,
                label: item?.circle_name,
              }))}
              isOptionDisabled={(option) => option.disabled}
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              isDisabled={loading}
              onChange={(value) => {
                setCurrMomentCircle(value);
              }}
              hideSelectedOptions
              defaultValue={currSharedMoment?.share_with?.map((item) => ({
                value: item?._id,
                label: item?.circle_name,
              }))}
            />
          </div>
        </Modal.Body>{" "}
      </Modal>

      <Modal
        show={showFiltersModal}
        size="lg"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body
          className="p-0"
          style={{
            height: 400,
          }}
        >
          <div className="p-3">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <p className="m-0 ff-tw">Filters</p>
              <div>
                <i
                  class="bi bi-check-lg ms-3 fs-20 pointer"
                  onClick={handleFilterModalClose}
                ></i>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <p className="m-0 ff-tw fs-16 fc-21 me-5">Select date</p>
              <div>
                <Flatpickr
                  options={{
                    dateFormat: "J F Y",
                  }}
                  data-enable-time
                  value={filter?.date || new Date()}
                  onChange={(d) => {
                    const date = d[0];
                    setFilter({
                      date,
                    });
                  }}
                  className="m-0 shadow-sm form-control ff-inter fw-500 fs-14"
                  style={{
                    color: "#000",
                    fontSize: 15,
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>{" "}
      </Modal>
      {feedLoading ? (
        <div className="container px-0 px-md-4">
          <div className="row m-0 p-0">
            <div className="col-12 col-md-11 d-flex py-4 align-items-center justify-content-center mx-auto p-0 feeds-container">
              <div class="spinner-border" role="status">
                <span class="sr-only"></span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container px-0 px-md-4">
          <div className="row m-0 p-0">
            <div
              ref={feedContainerRef}
              className="col-12 col-md-11 d-flex flex-column mx-auto p-0 feeds-container"
              style={{
                height: FRAME_HEIGHT,
              }}
            >
              {feeds &&
                feeds?.map((item, index) => {
                  const isLiked = item?.liked_by?.includes(userDbData?._id);
                  const likesCount = item?.liked_by?.length;
                  return (
                    <div
                      key={item?.id + index.toString()}
                      className="row m-0 p-0 w-100 feed-item justify-content-center"
                      style={{
                        minHeight: FRAME_HEIGHT,
                      }}
                    >
                      <div className="col-12 col-md-5 position-relative p-0">
                        <VideoCardShorts
                          height={FRAME_HEIGHT - 4}
                          item={item}
                          videoRef={videoRef}
                          vidBg="#EFF5F5"
                        />
                        <div
                          className="position-absolute d-block d-md-none bottom-0 right-0 end-0"
                          style={{
                            zIndex: 99,
                          }}
                        >
                          <FloatingButtons
                            isAbsolute
                            handleMomentSharing={() =>
                              handleMomentSharing(item)
                            }
                            handleLikeUnlike={() => handleLikeUnlike(item)}
                            isLiked={isLiked}
                            likesCount={likesCount}
                          />
                        </div>
                      </div>
                      <div className="col-1 d-none d-md-flex justify-content-end flex-column position-relative p-0">
                        <FloatingButtons
                          handleMomentSharing={() => handleMomentSharing(item)}
                          handleLikeUnlike={() => handleLikeUnlike(item)}
                          isLiked={isLiked}
                          likesCount={likesCount}
                        />
                      </div>
                    </div>
                  );
                })}

              {feeds?.length === 0 && (
                <div>
                  <p className="m-0 text-secondary text-center mt-2">No Feed</p>
                  {isAdmin && !school_id && (
                    <p className="m-0 text-secondary fw-500 text-center mt-2">
                      Please select a school from sidebar.
                    </p>
                  )}
                </div>
              )}
            </div>

            <div className="col-0 col-md-1 d-none d-md-flex justify-content-between flex-column position-relative p-0">
              {feeds?.length !== 0 && (
                <div className="action-btn-box d-flex align-items-center justify-content-center">
                  <i
                    class="bi bi-arrow-down fs-20 border btn-box pointer rounded-circle bg-light d-flex align-items-center justify-content-center"
                    onClick={handleScrollDown}
                  ></i>
                </div>
              )}

              <div className="action-btn-box d-flex align-items-center justify-content-center mt-auto">
                <Tooltip title="Filters" placement="top">
                  <i
                    onClick={() => {
                      setShowFilterModal(true);
                    }}
                    className="bi bi-filter fs-24 border btn-box pointer rounded-circle bg-light d-flex align-items-center justify-content-center"
                  ></i>
                </Tooltip>
              </div>

              {feeds?.length !== 0 && (
                <div className="action-btn-box d-flex align-items-center justify-content-center">
                  <i
                    class="bi bi-arrow-up fs-20 border btn-box pointer rounded-circle bg-light d-flex align-items-center justify-content-center"
                    onClick={handleScrollUp}
                  ></i>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShortsUI;
