import { useContext } from "react";
import {
  USER_ROLES,
  demoAccountSidebarOptions,
  sidebarOptions,
} from "../constants/appConstants";
import { AppContext } from "../context/AppContext";
import { useHistory } from "react-router-dom";
import { firebase } from "./../firebase/config";
import CryptoJS from "crypto-js";

export const getQueryParameters = (query) => {
  const obj = {};
  const queryArr = query?.replace("?", "")?.split("&");

  queryArr?.forEach((item) => {
    const splitted = item?.split("=");
    obj[splitted[0]] = splitted[1];
  });

  return obj;
};

export const getInitials = (name) => {
  return name?.split(" ")[0];
};

export const getDPInitials = (name) =>
  name?.split(" ")?.reduce((p, p1) => p + p1?.[0], "");

export const getSidebarOptions = (
  schoolsList,
  isAdmin,
  isSchoolAdmin,
  isDemoAccount
) => {
  if (!isAdmin && !isSchoolAdmin) {
    return sidebarOptions;
  }

  if (isDemoAccount) {
    return demoAccountSidebarOptions;
  }

  const options = sidebarOptions?.map((opt) =>
    opt?.route === "/moments"
      ? {
          ...opt,
          subMenu: schoolsList?.map((school) => ({
            item: school?.name,
            value: school?._id,
          })),
        }
      : opt
  );

  return options;
};

export const updateContext = (setValueByKey, userDetails, options) => {
  console.log(userDetails, "userDetails");
  setValueByKey("userDbData", userDetails?.user);

  if (!options?.onlyUser) {
    setValueByKey("schoolsList", userDetails?.schoolsList);
    setValueByKey("isAdmin", userDetails?.isAdmin);
    setValueByKey("isSchoolAdmin", userDetails?.isSchoolAdmin);
    setValueByKey(
      "isTeacher",
      userDetails?.user?.role?.role_name === USER_ROLES.TEACHER
    );
  }
};

export const useAuth = () => {
  const { setValueByKey, setUser } = useContext(AppContext);
  const history = useHistory();

  const logoutUser = () =>
    firebase
      .auth()
      .signOut()
      .then(() => {
        history.push("/login");
        setValueByKey("userDbData", null);
        setUser(null);
      });
  return { logoutUser };
};

export const getFormattedDate = (date) => {
  const splitted = date.toString()?.split(" ");
  const mon = (new Date(date).getMonth() + 1)?.toString()?.padStart(2, "0");
  const year = splitted[3];
  const day = splitted[2];

  return `${year}-${mon}-${day}`;
};

export const redirectUser = (history, setValueByKey, route) => {
  setValueByKey("loading", false);
  history.push(route);
};

export const initApp = (
  userDbData,
  isDemoAccount,
  history,
  setValueByKey,
  location
) => {
  if (!userDbData) return;
  setValueByKey("loading", true);
  const currUrl = location?.pathname;
  console.log(currUrl, userDbData);
  //check user role and act accordingly
  // check if role is Teacher
  if (userDbData?.role?.role_name === USER_ROLES.TEACHER) {
    //check whether the onboarding is completed or not
    if (!userDbData?.onboardingCompleted) {
      //redirect user to onboarding page
      return redirectUser(history, setValueByKey, "/onboarding/teacher");
    }
    //TODO: check school payment subscription

    //if everything is OK then redirect to moments

    // return redirectUser(history, setValueByKey, "/moments");
  }
  //check school admin role
  else if (userDbData?.role?.role_name === USER_ROLES.SCHOOL_ADMIN) {
    //TODO: check school payment subscription
    // redirectUser(history, setValueByKey, "/moments");
  } else if (userDbData?.role?.role_name === USER_ROLES.SUPER_ADMIN) {
    //no check
    // if (isDemoAccount) {
    //   redirectUser(history, setValueByKey, "/moments_");
    // } else {
    //   redirectUser(history, setValueByKey, currUrl);
    // }
  }
  setValueByKey("loading", false);
};

export const encryptToken = (token) => {
  const secretKey = process.env.REACT_APP_DECRYPTION_SECRET_KEY;
  // Encrypt the token using AES encryption with the provided secret key
  const encryptedToken = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(token),
    secretKey
  ).toString();
  return encryptedToken;
};

export const decryptToken = (encryptedToken) => {
  const secretKey = process.env.REACT_APP_DECRYPTION_SECRET_KEY;
  // Decrypt the encrypted token using AES decryption with the provided secret key
  const decryptedTokenBytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const decryptedToken = decryptedTokenBytes.toString(CryptoJS.enc.Utf8);
  return decryptedToken;
};
