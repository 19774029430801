import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { OnboardingHeader } from "../../../components/common/onboarding-header";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import classroomServices from "../../../services/classroomServices";
import userServices from "../../../services/userServices";
import { useHistory } from "react-router-dom";

import "./teacher-onboarding.css";
import Loader from "../../../components/loader/loader";
import { updateContext } from "../../../utils/appUtils";

export const TeacherOnboarding = () => {
  const { userDbData, setValueByKey } = useContext(AppContext);
  const animatedComponents = makeAnimated();
  const [isLoading, setLoading] = useState(false);
  const [classroomList, setClassroomList] = useState([]);
  const [selectedClassrooms, setSelectedClassrooms] = useState([]);
  const history = useHistory();

  const init = async () => {
    setLoading(true);
    try {
      const classroomListRes = await classroomServices.getAllClassrooms({
        school_id: userDbData?.school_id?.[0]?._id,
      });
      setClassroomList(classroomListRes?.classrooms);
      setSelectedClassrooms(userDbData?.classroom_id?.map((item) => item?._id));
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const completeTeacherOnboarding = async () => {
    if (selectedClassrooms?.length === 0) {
      window.alert("Please add classroom.");
      return;
    }
    setLoading(true);
    try {
      const res = await userServices.teacherOnboarding({
        classrooms: selectedClassrooms,
        user_id: userDbData?._id,
      });
      if (res?.status === 200) {
        try {
          const userDetails = await userServices.fetchUserDetails({
            checkValue: userDbData?.email || userDbData?.phoneNumber,
          });

          updateContext(setValueByKey, userDetails);
          history.push("/moments");
        } catch (err) {}
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!userDbData || classroomList?.length > 0) return;

    if (userDbData?.onboardingCompleted) {
      return history.push("/");
    }
    init();
  }, [userDbData]);

  if (userDbData === null) {
    return (
      <div>
        <Loader size={25} />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column">
      <div className="onboarding-header">
        <div className="container">
          <OnboardingHeader />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-11 col-md-12 mx-auto rounded bg-white p-4 shadow onboarding-main">
            {!isLoading ? (
              <div className="d-flex flex-column">
                <p className="m-0 ff-tw fw-600 fs-20 fc-cprimary">
                  Hello, {userDbData?.name}
                </p>
                <p>Please complete your profile before using the app.</p>

                <div className="col-12 col-md-6 my-2">
                  <div className="rounded select-classroom">
                    <Select
                      options={classroomList?.map((item) => ({
                        value: item?._id,
                        label: item?.classroom_name,
                      }))}
                      required
                      isOptionDisabled={(option) => option.disabled}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      isDisabled={false}
                      onChange={(values) => {
                        setSelectedClassrooms(
                          values?.map((classroom) => classroom?.value)
                        );
                      }}
                      defaultValue={userDbData?.classroom_id?.map((item) => ({
                        value: item?._id,
                        label: item?.classroom_name,
                      }))}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-end mt-auto">
                  <button
                    onClick={completeTeacherOnboarding}
                    className="btn btn-outline-primary rounded px-4 py-1"
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <Loader size={25} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
