import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import "firebase/compat/messaging";
import { onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAHwI7XnJJiYOxjFUWGWom_CkUOEYKqHgU",
  authDomain: "class-proxima-e9dd5.firebaseapp.com",
  projectId: "class-proxima-e9dd5",
  storageBucket: "class-proxima-e9dd5.appspot.com",
  messagingSenderId: "763642869266",
  appId: "1:763642869266:web:f44d3b0b025a4069808acb",
  measurementId: "G-P0ZBLHVKQX",
};
const app = firebase.initializeApp(firebaseConfig);

firebase.firestore().settings({ timestampsInSnapshots: true });

export const auth = firebase.auth();

const messaging = firebase.messaging();

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

export { firebase, messaging };
