export const EVENT_TYPES = {
  0: ["one_on_one_interaction", "group_interaction", "affection", "caretaking"],
  1: ["classroom_chores", "admin_work", "idle"],
  2: ["no_adult_supervision", "undesirable_interaction"],
};
export const EVENT_LABELS = {
  one_on_one_interaction: "1 on 1 Interactions",
  group_interaction: "Group Interactions",
  affection: "Affection",
  caretaking: "Caretaking",
};

export const EVENT_VIDEOS = {
  one_on_one_interaction: [
    "https://d1qxbes4w1wxkf.cloudfront.net/segments/2022-10-06/2022-10-06.camera22.1665070983.e2a1fea7-818a-45f6-96e9-271a05930a33_5.mp4",
    "https://d1qxbes4w1wxkf.cloudfront.net/segments/2022-09-19/2022-09-19.camera24.1663607060.e2a1fea7-818a-45f6-96e9-271a05930a33_5.mp4",
    "https://d1qxbes4w1wxkf.cloudfront.net/segments/2022-09-28/2022-09-28.camera14.1664382386.e2a1fea7-818a-45f6-96e9-271a05930a33_0.mp4",
  ],
  group_interaction: [
    "https://d1qxbes4w1wxkf.cloudfront.net/segments/2022-10-06/2022-10-06.camera1.1665072738.e2a1fea7-818a-45f6-96e9-271a05930a33_2.mp4",
    "https://d1qxbes4w1wxkf.cloudfront.net/segments/2022-09-14/2022-09-14.camera27.1663174938.e2a1fea7-818a-45f6-96e9-271a05930a33_0.mp4",
    "https://d1qxbes4w1wxkf.cloudfront.net/segments/2022-09-19/2022-09-19.camera24.1663604000.e2a1fea7-818a-45f6-96e9-271a05930a33_0.mp4",
    "https://d1qxbes4w1wxkf.cloudfront.net/segments/2022-09-21/2022-09-21.camera24.1663790772.e2a1fea7-818a-45f6-96e9-271a05930a33_0.mp4",
  ],
  affection: [
    "https://d1qxbes4w1wxkf.cloudfront.net/segments/2022-08-30/2022-08-30.camera31.1661892911_6.mp4",
    "https://d1qxbes4w1wxkf.cloudfront.net/segments/2022-10-05/2022-10-05.camera1.1664983021.e2a1fea7-818a-45f6-96e9-271a05930a33_0.mp4",
    "https://d1qxbes4w1wxkf.cloudfront.net/segments/2022-09-14/2022-09-14.camera31.1663162179.e2a1fea7-818a-45f6-96e9-271a05930a33_0.mp4",
    "https://d1qxbes4w1wxkf.cloudfront.net/segments/2022-09-13/2022-09-13.camera27.1663090737.e2a1fea7-818a-45f6-96e9-271a05930a33_6.mp4",
  ],
  caretaking: [
    "https://d1qxbes4w1wxkf.cloudfront.net/segments/2022-10-10/2022-10-10.camera27.1665409219.e2a1fea7-818a-45f6-96e9-271a05930a33_0.mp4",
  ],
};
