import { Avatar } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getDPInitials, getInitials } from "../../utils/appUtils";
import { deepPurple } from "@mui/material/colors";
export const Header = () => {
  const { userDbData, setValueByKey, user } = useContext(AppContext);

  const { height } = useWindowDimensions();
  const HEADER_HEIGHT = height * 0.08;
  return (
    <div
      className="px-4 border-bottom d-flex justify-content-between align-items-center"
      style={{
        height: HEADER_HEIGHT,
      }}
    >
      <div className="d-flex align-items-center">
        <i
          class="bi bi-list d-flex d-md-none fs-24 me-2 pointer"
          onClick={() => {
            setValueByKey("sidebarVisible", true);
          }}
        ></i>
        <p className="m-0 ff-tw fw-600 fs-20">
          Hello{" "}
          {userDbData?.username
            ? userDbData?.username
            : getInitials(userDbData?.name || user?.displayName)}
        </p>
      </div>
      <div className="d-flex align-items-center">
        {/* <Link to="/">
          <i className="bi bi-bell fs-24 me-4"></i>
        </Link> */}

        {/* <Link to="/settings">
          <i class="bi bi-gear fs-24 me-4"></i>
        </Link> */}
        <Avatar className="fs-14" sx={{ bgcolor: deepPurple[500] }}>{getDPInitials(userDbData?.name || user?.displayName)}</Avatar>
      </div>
    </div>
  );
};
