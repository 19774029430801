import { useContext, useEffect } from "react";
import "./App.css";
import Loader from "./components/loader/loader";
import AppContextProvider, { AppContext } from "./context/AppContext";
import { firebase, messaging } from "./firebase/config";
import { useHistory, useLocation } from "react-router-dom";
import AppRoutes from "./routes/app-routes";
import { Chart, registerables } from "chart.js";
import { Toaster } from "react-hot-toast";
import annotationPlugin from "chartjs-plugin-annotation";

import UserServices from "./services/userServices";
import {
  decryptToken,
  encryptToken,
  initApp,
  updateContext,
} from "./utils/appUtils";
import { demoAccounts } from "./constants/appConstants";
Chart.register(...registerables, annotationPlugin);
const AppHelper = () => {
  const { loading, setLoading, setUser, user, setValueByKey, userDbData } =
    useContext(AppContext);
  const history = useHistory();
  const isDemoAccount = demoAccounts?.includes(userDbData?.email);
  const location = useLocation();

  //fetch user details available in db
  useEffect(() => {
    if (user && !userDbData) {
      setLoading(true);

      UserServices.fetchUserDetails({
        checkValue: user?.email || user?.phoneNumber,
      })
        .then((userDetails) => {
          updateContext(setValueByKey, userDetails);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    initApp(userDbData, isDemoAccount, history, setValueByKey, location);
  }, [userDbData]);

  useEffect(() => {
    setLoading(true);
    const unsub = firebase.auth().onAuthStateChanged(async (user) => {
      if (!user) {
        history.push("/login");
      } else {
        const tokenKey = process.env.REACT_APP_LOCAL_STORAGE_KEY;
        const token = await user.getIdToken(true);
        const encryptedToken = encryptToken(token);
        localStorage.setItem(tokenKey, encryptedToken);
        setUser(user);
      }
      setLoading(false);
    });

    return unsub;
    // eslint-disable-next-line
  }, []);

  const a = async () => {
    const tt = await messaging.getToken({
      vapidKey:
        "BA60KKCLeUboVm4EigusCMLVngav8VLsy5QyjVa6PqwcNVLzZdFduI6SCB56MBu551EN57GUCyqnKZe4Ox6gpjI",
    });
  };

  useEffect(() => {
    a();
  }, []);

  return (
    <div className="app">
      {loading && (
        <div
          className="d-flex align-items-center justify-content-center h-100 position-absolute w-100 bg-light"
          style={{
            zIndex: 9999,
          }}
        >
          <Loader />
        </div>
      )}
      <AppRoutes />
    </div>
  );
};

function App() {
  return (
    <AppContextProvider>
      <Toaster />
      <AppHelper />
    </AppContextProvider>
  );
}

export default App;
