import { Route, Switch } from "react-router-dom";
import { LandingPage } from "../pages/landing-page/landing-page";
import { Login } from "../pages/login/login";
import NotFound from "../pages/not-found/not-found";
import { MobileLogin } from "../pages/login/mobile-login";
import { Register } from "../pages/register/register";
import { ForgotPassword } from "../pages/forgot-password/forgot-password";
import { TeacherOnboarding } from "../pages/onboarding/teacher/teacher-onboarding";

const AppRoutes = () => {
  return (
    <Switch>
      <Route path="/login" component={Login} exact />
      <Route path="/mobile-login" component={MobileLogin} exact />
      <Route path="/register" component={Register} exact />
      <Route path="/forgot-password" component={ForgotPassword} exact />
      <Route path="/onboarding/teacher" component={TeacherOnboarding} />
      <Route path="/" component={LandingPage} />
      <Route path={["/not-found", "*"]} component={NotFound} exact />
    </Switch>
  );
};

export default AppRoutes;
