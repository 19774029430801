import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { firebase } from "../../firebase/config";
import {
  getQueryParameters,
  getSidebarOptions,
  useAuth,
} from "../../utils/appUtils";
import "./sidebar-mobile.css";
import { demoAccounts } from "../../constants/appConstants";

export const SidebarContent = ({
  setActiveTab,
  activeTab,
  setActiveSchool,
  activeSchool,
  SIDEBAR_OPTIONS,
  isDemoAccount,
}) => {
  const history = useHistory();
  const { setUser, setValueByKey, isAdmin, isSchoolAdmin } =
    useContext(AppContext);
  const { logoutUser } = useAuth();

  return (
    <>
      <div className="sidebar-contents p-2">
        <p className="m-0 text-white ff-tw fs-20 text-center py-2 pb-3">
          Class Proxima
        </p>
        {SIDEBAR_OPTIONS?.map((opt, index) => {
          const isActive = opt?.label === activeTab?.label;
          return (
            <>
              <div
                onClick={() => {
                  setActiveTab(opt);
                  history.push(opt?.route);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  if (
                    index === 0 &&
                    (isAdmin || isSchoolAdmin) &&
                    !isDemoAccount
                  ) {
                    const defOption = opt?.subMenu?.[0];
                    setActiveSchool(defOption);
                    history.push(`?school_id=${defOption?.value}`);
                  }
                }}
                key={`OPT_${index}`}
                className={`my-2 p-2 px-3 d-flex align-items-center ${
                  isActive
                    ? "active-tab text-orange fw-500"
                    : "text-white fw-400"
                } pointer text-orange rounded`}
              >
                <i className={`bi ${opt?.iconName} fs-18`}></i>
                <p className="m-0 fs-14 ms-2">{opt?.label}</p>
                {opt?.subMenu?.length > 0 && (isAdmin || isSchoolAdmin) && (
                  <i class="bi bi-chevron-down ms-auto"></i>
                )}
              </div>
              {isActive && (isAdmin || isSchoolAdmin) && (
                <ul className="">
                  {opt?.subMenu?.map((school) => {
                    const isActiveSchool =
                      school?.value === activeSchool?.value;
                    return (
                      <li
                        onClick={() => {
                          setActiveSchool(school);
                          history.push(`?school_id=${school?.value}`);
                        }}
                        className={`my-2 pointer text-${
                          isActiveSchool ? "orange" : "secondary"
                        }`}
                      >
                        <p className="ff-tw fw-500 m-0">{school?.item}</p>
                      </li>
                    );
                  })}
                </ul>
              )}
            </>
          );
        })}
      </div>
      <div>
        <div
          onClick={logoutUser}
          className={`my-2 p-2 px-3 d-flex align-items-center text-white fw-400
    pointer text-orange rounded justify-content-center`}
        >
          <i className="bi bi-box-arrow-left fs-18"></i>
          <p className="m-0 fs-14 ms-3">Logout</p>
        </div>
      </div>
    </>
  );
};

export const Sidebar = () => {
  const {
    setValueByKey,
    sidebarVisible,
    schoolsList,
    isAdmin,
    userDbData,
    isSchoolAdmin,
    isTeacher,
  } = useContext(AppContext);
  const isDemoAccount = demoAccounts?.includes(userDbData?.email);
  const SIDEBAR_OPTIONS = getSidebarOptions(
    schoolsList,
    isAdmin,
    isSchoolAdmin,
    isDemoAccount
  );
  const [activeTab, setActiveTab] = useState(SIDEBAR_OPTIONS[0]);
  const [activeSchool, setActiveSchool] = useState(
    SIDEBAR_OPTIONS[0]?.subMenu?.[0]
  );
  const location = useLocation();
  const history = useHistory();

  //for setting the current tab from URL
  useEffect(() => {
    const currUrl = location.pathname;
    const currTab = SIDEBAR_OPTIONS?.filter(
      (opt) => opt?.route === currUrl
    )?.[0];

    if (currTab) {
      setActiveTab(currTab);
    }
    const school_id = getQueryParameters(location.search)?.school_id;
    if (school_id) {
      setActiveSchool(
        SIDEBAR_OPTIONS[0]?.subMenu?.filter(
          (opt) => opt?.value === school_id
        )?.[0]
      );
    }

    //for alerts
    const name = getQueryParameters(location.search)?.name;
    if (name) {
      const tab = SIDEBAR_OPTIONS?.filter((opt) => opt?.name === name)?.[0];
      if (tab) {
        setActiveTab(tab);
      }
    }
  }, [location, isAdmin, isSchoolAdmin]);

  //for setting the current school from URL
  useEffect(() => {
    const school_id = getQueryParameters(location.search)?.school_id;
    const currUrl = location?.pathname;
    if (
      !school_id &&
      (isAdmin || isSchoolAdmin) &&
      !isDemoAccount &&
      currUrl?.includes("moments")
    ) {
      const defSchool = SIDEBAR_OPTIONS[0]?.subMenu?.[0];
      setActiveSchool(defSchool);
      history.push(`?school_id=${defSchool?.value}`);
    }
    console.log(currUrl, isTeacher, currUrl === "/", "sdads");
    if (isTeacher && currUrl === "/") {
      history.push("/moments");
    }

    if ((isAdmin || isSchoolAdmin) && currUrl === "/") {
      const defSchool = SIDEBAR_OPTIONS[0]?.subMenu?.[0];
      history.push(
        `/moments${isDemoAccount ? "_" : ""}${defSchool ? `?school_id=${defSchool?.value}` : ""}`
      );
    }
  }, [isAdmin, isSchoolAdmin, isTeacher]);

  return (
    <>
      {/*for desktop view */}
      <div className="sidebar col-2 p-0 d-none d-md-flex flex-column justify-content-between">
        <SidebarContent
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          activeSchool={activeSchool}
          setActiveSchool={setActiveSchool}
          SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
          isDemoAccount={isDemoAccount}
        />
      </div>
      {/*for mobile view */}
      <div className="d-md-none">
        <div
          className={`mobile-sidebar-wrapper ${
            sidebarVisible ? "show" : ""
          } position-fixed w-100 h-100 start-0 end-0 top-0 bottom-0`}
          style={{ zIndex: 99 }}
        >
          <div className="row">
            <div
              className={`sidebar mobile-sidebar ${
                sidebarVisible ? "show" : ""
              } col-7 d-flex flex-column justify-content-between p-0`}
            >
              <SidebarContent
                activeSchool={activeSchool}
                setActiveSchool={setActiveSchool}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
                isDemoAccount={isDemoAccount}
              />
            </div>
            <div
              className={`mobile-sidebar-overlay ${
                sidebarVisible ? "show" : ""
              } col-5`}
              style={{ background: "#00000040" }}
              onClick={() => {
                setValueByKey("sidebarVisible", false);
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};
