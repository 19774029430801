import Chart from "react-apexcharts";
import { useMediaQuery } from "react-responsive";

export const AllClassrooms = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  const state = {
    series: [
      {
        name: "Interaction Score",
        data: [10, 2, 5, 6, 7, 5],
      },
    ],
    options: {
      fill: {
        colors: ["#4582ff"],
      },
      markers: {
        colors: ["#4582ff"],
      },
      colors: ["#4582ff"],
      chart: {
        type: "bar",
        height: 430,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: isTabletOrMobile ? 20 : 12,
          columnWidth: "70%",
          borderRadiusApplication: "end",
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -2,
        offsetY: 10,

        style: {
          fontSize: "12px",
          colors: ["#FFF"],
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: [
          "Class 1",
          "Class 2",
          "Class 3",
          "Class 4",
          "Class 5",
          "Class 6",
        ],
      },
    },
  };
  return (
    <Chart
      options={state.options}
      series={state.series}
      type="bar"
      height={430}
    />
  );
};
