import { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { auth } from "../../firebase/config";

const USER_MEGS = {
  USER_DATA_NOT_FOUND: {
    success: false,
    msg: "You are not registered with Us. Please contact Class Proxima.",
  },
  LINK_SEND: {
    success: true,
    msg: "Please check you email. We have send an password reset link to your email.",
  },
};

const ForgotPassword = () => {
  const [userMsg, setUserMsg] = useState(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setUserMsg(null);
    var formData = new FormData(e.target);
    const email = formData.get("email");

    setLoading(true);

    try {
      await auth?.sendPasswordResetEmail(email);

      setUserMsg(USER_MEGS.LINK_SEND);
      e?.target?.reset();

      setTimeout(() => {
        history.push("/login");
      }, 2000);
    } catch (err) {
      console.log(err?.code, err);
      if (err?.code === "auth/user-not-found") {
        setUserMsg(USER_MEGS.USER_DATA_NOT_FOUND);
      } else {
        setUserMsg({
          success: false,
          msg: err?.message,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div id="re-c"></div>

      <div className="row">
        <div className="col-9 col-md-4 mx-auto my-4">
          <h4 className="text-center mb-3">Forgot Password</h4>
          <form onSubmit={handleSubmit}>
            <div className="mb-2">
              <label className="form-label">Email</label>
              <input
                disabled={loading}
                required
                type="email"
                name="email"
                className="form-control shadow-none"
                readOnly={loading}
              />
            </div>

            <p
              style={{
                visibility: userMsg ? "visible" : "hidden",
              }}
              className={`m-0 text-${
                userMsg?.success ? "success" : "danger"
              } mb-2`}
            >
              {userMsg ? userMsg?.msg : "Hello"}
            </p>
            <button
              disabled={loading}
              type="submit"
              className="btn w-100 btn-outline-primary shadow-none"
            >
              {loading ? (
                <>
                  <span
                    class="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Loading...</span>
                </>
              ) : (
                "Send Reset Link"
              )}
            </button>
          </form>
          <div className="d-flex mt-3 justify-content-between">
            <Link className="fw-500" to="/login">
              Back to Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ForgotPassword };
