import { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { auth } from "../../firebase/config";
import UserServices from "../../services/userServices";

const USER_MEGS = {
  USER_DATA_NOT_FOUND: {
    success: false,
    msg: "User data not found. Please contact Class Proxima.",
  },
  PASSWORD_NOT_MACTHED: {
    success: false,
    msg: "Password doesn't matched with the confirm password.",
  },
  EMAIL_NOT_VERIFIED: {
    success: false,
    msg: "Please Verify Your Email Address",
  },
  USER_ACCOUNT_ALREADY_CREATED: {
    success: false,
    msg: "User account already exists.",
  },
  USER_ACCOUNT_CREATED: {
    success: true,
    msg: "Account created successfully. Please click on the link that has been sent to your email account to verify your email.",
  },
};

const Register = () => {
  const [userMsg, setUserMsg] = useState(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setUserMsg(null);
    var formData = new FormData(e.target);
    const email = formData.get("email");
    const password = formData.get("password");
    const confirmPassword = formData.get("confirmPassword");

    if (password !== confirmPassword) {
      setUserMsg(USER_MEGS.PASSWORD_NOT_MACTHED);
      return;
    }

    setLoading(true);

    try {
      const isUser = await UserServices.checkUser({ checkValue: email });
      if (!isUser?.userFound) {
        setUserMsg(USER_MEGS.USER_DATA_NOT_FOUND);
        setLoading(false);
        return;
      }
    } catch (err) {}

    try {
      await auth?.createUserWithEmailAndPassword(email, password);

      setUserMsg(USER_MEGS.USER_ACCOUNT_CREATED);
      e?.target?.reset();

      setTimeout(() => {
        history.push("/login");
      }, 2000);
    } catch (err) {
      console.log(err?.code, err);
      if (err?.code === "auth/email-already-in-use") {
        setUserMsg(USER_MEGS.USER_ACCOUNT_ALREADY_CREATED);
      } else {
        setUserMsg({
          success: false,
          msg: err?.message,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div id="re-c"></div>

      <div className="row">
        <div className="col-9 col-md-4 mx-auto my-4">
          <h4 className="text-center mb-3">Register with Class Proxima</h4>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="form-label">Email</label>
              <input
                disabled={loading}
                required
                type="email"
                name="email"
                className="form-control shadow-none"
                readOnly={loading}
              />
            </div>
            <div className={"mb-4"}>
              <label className="form-label">Password</label>
              <input
                disabled={loading}
                required
                type="password"
                name="password"
                className="form-control shadow-none"
                readOnly={loading}
              />
            </div>
            <div className={`mb-2`}>
              <label className="form-label">Confirm Password</label>
              <input
                disabled={loading}
                required
                type="password"
                name="confirmPassword"
                className="form-control shadow-none"
                readOnly={loading}
              />
            </div>

            <p
              style={{
                visibility: userMsg ? "visible" : "hidden",
              }}
              className={`m-0 text-${
                userMsg?.success ? "success" : "danger"
              } mb-2`}
            >
              {userMsg ? userMsg?.msg : "Hello"}
            </p>
            <button
              disabled={loading}
              type="submit"
              className="btn w-100 btn-outline-primary shadow-none"
            >
              {loading ? (
                <>
                  <span
                    class="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Loading...</span>
                </>
              ) : (
                "Register"
              )}
            </button>
          </form>
          <div className="d-flex mt-3 justify-content-between">
            <p className="m-0 ff-tw">Already have an account?</p>{" "}
            <Link className="fw-500" to="/login">
              Login here
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Register };
