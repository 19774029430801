import { useContext, useEffect } from "react";
import { Route, useLocation, useHistory } from "react-router-dom";
import { Header } from "../../components/header/header";
import { Sidebar } from "../../components/sidebar/sidebar";
import { AppContext } from "../../context/AppContext";
import { MainHome } from "../main-home/main-home";
import ShortsUI from "../shorts-ui/shorts-ui";
import "./landing-page.css";
import { demoAccounts } from "../../constants/appConstants";
import { initApp } from "../../utils/appUtils";

export const LandingPage = () => {
  const location = useLocation();
  const { setValueByKey, userDbData } = useContext(AppContext);
  const isDemoAccount = demoAccounts?.includes(userDbData?.email);
  const history = useHistory();

  useEffect(() => {
    setValueByKey("sidebarVisible", false);
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    initApp(userDbData, isDemoAccount, history, setValueByKey);
  }, [userDbData]);

  return (
    <div className="row m-0 h-100">
      <Sidebar />
      <div className="col-12 col-md-10 content p-0">
        <Header />
        <Route exact path="/statistics" component={MainHome} />
        <Route exact path="/moments" component={ShortsUI} />
      </div>
    </div>
  );
};
