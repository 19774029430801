import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { firebase } from "../../firebase/config";
import userServices from "../../services/userServices";

const provider = new firebase.auth.GoogleAuthProvider();

const USER_MEGS = {
  NUMBER_NOT_FORMATTED: {
    success: false,
    msg: "Please enter a valid number.",
  },
  OTP_SENT_SUCCESS: {
    success: true,
    msg: "Otp has been sent please check your mobile.",
  },
  OTP_SENT_FAILURE: {
    success: false,
    msg: "Please try again.",
  },
  OTP_VERIFY_SUCCESS: {
    success: true,
    msg: "Otp verified successfully.",
  },
  OTP_VERIFY_FAILURE: {
    success: false,
    msg: "Incorrect otp.",
  },
  NOT_AUTHORIZED: {
    success: false,
    msg: "You aren't authorized to login",
  },
  OTP_LENGTH: {
    success: false,
    msg: "Please enter 6 digits.",
  },
};

const MobileLogin = (props) => {
  const [showOtp, setShowOtp] = useState(false);
  const [userMsg, setUserMsg] = useState(null);
  const { setUser } = useContext(AppContext);
  const history = useHistory();
  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("re-c", {
      size: "invisible",
    });
  };
  const removeCaptcha = () => (document.querySelector("#re-c").innerHTML = "");

  const handleSubmit = async (e) => {
    e.preventDefault();

    var formData = new FormData(e.target);
    const phoneNumber = formData.get("phoneNumber");
    const countryCode = formData.get("countryCode");
    const fullNumber = `+${countryCode}${phoneNumber}`;
    const otp = formData.get("otp");

    if (!showOtp) {
      userServices
        .fetchUserDetails({
          checkValue: fullNumber,
        })
        .then((res) => {
          if (res?.userFound) {
            setUpRecaptcha();
            const verify = window.recaptchaVerifier;

            firebase
              .auth()
              .signInWithPhoneNumber(fullNumber, verify)
              .then((result) => {
                window.confirm = result;
                removeCaptcha();

                setShowOtp(true);

                setUserMsg(USER_MEGS.OTP_SENT_SUCCESS);
              })
              .catch((err) => {
                console.log(err);
                setUserMsg(USER_MEGS.OTP_SENT_FAILURE);
                removeCaptcha();
              });
          } else {
            setUserMsg(USER_MEGS.NOT_AUTHORIZED);
            return;
          }
        });
    } else {
      if (otp?.length !== 6 || window?.confirm === null) {
        setUserMsg(USER_MEGS.OTP_LENGTH);
        return;
      }

      window.confirm
        .confirm(otp)
        .then((result) => {
          console.log("succes", result);
          history.push("/");
          setUserMsg(USER_MEGS.OTP_VERIFY_SUCCESS);
        })
        .catch((err) => {
          setUserMsg(USER_MEGS.OTP_VERIFY_FAILURE);
        });
    }
  };

  const signIn = () => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((data) => {
        const userData = data?.user?._delegate;
        userServices
          .fetchUserDetails({
            checkValue: userData?.email,
          })
          .then((res) => {
            if (res?.userFound) {
              localStorage.setItem("USER_DATA", JSON.stringify(userData));
              history.push("/");
              setUser(userData);
            }
          });

        console.log(data?.user?._delegate?.email);
      })
      .catch((error) => alert(error.message));
  };
  return (
    <div className="container">
      <div id="re-c"></div>

      <div className="row">
        <div className="col-9 col-md-4 mx-auto my-4">
          <h4 className="text-center mb-3">Login using Mobile</h4>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="form-label">Country Code</label>
              <input
                required
                type="number"
                name="countryCode"
                className="form-control shadow-none"
                readOnly={showOtp}
              />
            </div>
            <div className={`mb-${showOtp ? "4" : "1"}`}>
              <label className="form-label">Phone Number</label>
              <input
                required
                type="number"
                name="phoneNumber"
                className="form-control shadow-none"
                readOnly={showOtp}
              />
            </div>

            {showOtp && (
              <div className="mb-2">
                <label className="form-label">One Time Password</label>
                <input
                  required
                  type="number"
                  name="otp"
                  className="form-control shadow-none"
                />
              </div>
            )}

            <p
              style={{
                visibility: userMsg ? "visible" : "hidden",
              }}
              className={`m-0 text-${
                userMsg?.success ? "success" : "danger"
              } mb-2`}
            >
              {userMsg ? userMsg?.msg : "Hello"}
            </p>
            <button
              type="submit"
              className="btn w-100 btn-outline-primary shadow-none"
            >
              Login
            </button>
          </form>

          <h4 className="text-center mb-3">Login using username</h4>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="form-label">Username</label>
              <input
                required
                type="email"
                name="Email"
                className="form-control shadow-none"
                readOnly={showOtp}
              />
            </div>
            <div className={`mb-${showOtp ? "4" : "1"}`}>
              <label className="form-label">Password</label>
              <input
                required
                type="password"
                name="phoneNumber"
                className="form-control shadow-none"
                readOnly={showOtp}
              />
            </div>

            {showOtp && (
              <div className="mb-2">
                <label className="form-label">One Time Password</label>
                <input
                  required
                  type="number"
                  name="otp"
                  className="form-control shadow-none"
                />
              </div>
            )}

            <p
              style={{
                visibility: userMsg ? "visible" : "hidden",
              }}
              className={`m-0 text-${
                userMsg?.success ? "success" : "danger"
              } mb-2`}
            >
              {userMsg ? userMsg?.msg : "Hello"}
            </p>
            <button
              type="submit"
              className="btn w-100 btn-outline-primary shadow-none"
            >
              Login
            </button>
          </form>

          {!showOtp && (
            <div className="my-5 border-top py-5">
              <div className="h-100 d-flex align-items-center justify-content-center">
                <button
                  className="w-100 btn btn-dark border d-flex justify-content-around"
                  onClick={signIn}
                >
                  <i className="bi bi-google"></i>
                  <p className="m-0 w-75 ff-tw fs-16 fw-500 text-center">
                    {" "}
                    Sign In With Google
                  </p>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { MobileLogin };
