import Chart from "react-apexcharts";
import { useMediaQuery } from "react-responsive";

export const ClassroomScores = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  const state = {
    series: [
      {
        name: "Average scores of All Classrooms",
        data: [7, 8, 9, 9, 6, 7, 6, 8, 4, 9, 6, 8, 7],
      },
      {
        name: "Score of Mayflower",
        data: [10, 2, 5, 6, 7, 5, 8, 5, 9, 7, 3, 5, 9],
      },
    ],
    options: {
      labels: ["Apples", "Oranges", "Berries", "Grapes"],
      fill: {
        colors: ["#D6D6D890", "#4582ff"],
      },
      markers: {
        colors: ["#D6D6D890", "#4582ff"],
      },
      colors: ["#D6D6D890", "#4582ff"],
      chart: {
        type: "bar",
        height: 430,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: isTabletOrMobile ? 6 : 12,
          columnWidth: isTabletOrMobile ? "90%" : "70%",
          borderRadiusApplication: "end",
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: 0,
        offsetY: 10,

        style: {
          fontSize: "12px",
          colors: ["#000", "#fff"],
          fontFamily: "Inter, sans-serif",
          fontWeight: "light",
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: [
          "1 March",
          "2 March",
          "3 March",
          "6 March",
          "7 March",
          "8 March",
          "9 March",
          "10 March",
          "13 March",
          "14 March",
          "15 March",
          "16 March",
          "17 March",
        ],
      },
    },
  };
  return (
    <Chart
      options={state.options}
      series={state.series}
      type="bar"
      height={430}
    />
  );
};
