import { useMediaQuery } from "react-responsive";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { EVENT_LABELS } from "../../utils/dashboardUtils";
import "./dashboard-feed-videos.css";
export const DashboardFeedVideos = ({ data }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const { height } = useWindowDimensions();

  console.log(data);
  return (
    <div>
      <div>
        <p className="m-0 ff-tw fw-400 fs-18 mb-2 user-select-none">
          Review clips of
          <span className="text-capitalize fw-600 ms-1">
            {EVENT_LABELS[data?.key]},
          </span>
        </p>

        <div className="dashboard-video-wrapper">
          {data?.feedVideos?.map((item) => (
            <video
              autoPlay
              muted="muted"
              className="mb-3 rounded border dashboard-video"
              loop
              controls
              style={{
                objectFit: isTabletOrMobile ? "fill" : "cover",
                height: height * 0.75,
              }}
            >
              <source src={item} type="video/mp4" />
            </video>
          ))}
        </div>
      </div>
    </div>
  );
};
