import { useState } from "react";
import Chart from "react-apexcharts";
import { Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { EVENT_TYPES, EVENT_VIDEOS } from "../../utils/dashboardUtils";
import { DashboardFeedVideos } from "../dashboard-feed-videos/dashboard-feed-videos";
import "./strength-attributes.css";


export const StrengthAttributes = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const [modalConfig, setModalConfig] = useState(null);
  const { height } = useWindowDimensions();
  const state = {
    series: [
      {
        name: "Average scores of All Classrooms",
        data: [7, 8, 9, 9],
      },
      {
        name: "Score of Mayflower",
        data: [10, 2, 5, 6],
      },
      {
        name: "Average scores of All Classrooms",
        data: [7, 8, 9, 9],
      },
      {
        name: "Score of Mayflower",
        data: [10, 2, 5, 6],
      },
    ],
    options: {
      fill: {
        colors: ["#eb9d04", "#28a5b8", "#9390d7", "#aac881"],
      },
      markers: {
        colors: ["#eb9d04", "#28a5b8", "#9390d7", "#aac881"],
      },
      colors: ["#eb9d04", "#28a5b8", "#9390d7", "#aac881"],
      chart: {
        type: "bar",
        height: 430,
        events: {
          dataPointSelection: (event, chartContext, config) => {
            handleBarClicked(EVENT_TYPES[0][config.seriesIndex]);
          },
        },
      },
      legend: {
        // show: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 4,
          borderRadiusApplication: "end",
          columnWidth: isTabletOrMobile ? "90%" : "70%",
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: 0,
        offsetY: 10,

        style: {
          fontSize: "12px",
          colors: ["#fff", "#fff"],
          fontFamily: "Inter, sans-serif",
          fontWeight: "light",
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        show: false,
        categories: ["Class 01", "Class 02", "Class 03", "Class 04"],
        tickAmount: 2,
      },
      yaxis: {
        tickAmount: 4,
        labels: {
          style: {
            cssClass: "ff-tw fw-600 fs-12",
          },
        },
      },
    },
  };

  const handleBarClicked = (key) => {
    setModalConfig({
      showModal: true,
      key,
      feedVideos: EVENT_VIDEOS[key],
    });
  };

  return (
    <div>
      <Modal
        show={modalConfig?.showModal}
        size="lg"
        backdrop="static"
        centered
        keyboard={false}
      >
        <Modal.Body
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            maxHeight: "90vh",
            minHeight: height * 0.8,
          }}
        >
          <div className="d-flex">
            <i
              onClick={() => setModalConfig(null)}
              class="bi pointer fs-20 bi-x ms-auto"
            ></i>
          </div>

          <DashboardFeedVideos data={modalConfig} />
        </Modal.Body>
      </Modal>

      <div>
        <p className="m-0 fw-400 fs-18 ff-tw">Classroom Quality Analysis:</p>
        <p className="m-0 fw-600 fs-20 ff-tw">Strength Attributes Score</p>
        <p className="m-0 fw-400 fs-14 ff-tw">
          Higher incidences of strength attributes result in a higher quality
          classroom environment.
        </p>

        <div className="d-flex flex-column align-items-start my-3">
          <button
            onClick={() => handleBarClicked("one_on_one_interaction")}
            className="btn mb-2 text-uppercase ff-inter fs-12 fw-500 one-on-one-btn"
          >
            1 on 1 interactions
          </button>
          <button
            onClick={() => handleBarClicked("group_interaction")}
            className="btn mb-2 text-uppercase ff-inter fs-12 fw-500 group-btn"
          >
            group interactions
          </button>
          <button
            onClick={() => handleBarClicked("affection")}
            className="btn mb-2 text-uppercase ff-inter fs-12 fw-500 affection-btn"
          >
            affection
          </button>
          <button
            onClick={() => handleBarClicked("caretaking")}
            className="btn mb-2 text-uppercase ff-inter fs-12 fw-500 caretaking-btn"
          >
            caretaking
          </button>
        </div>
      </div>
      <Chart
        options={state.options}
        series={state.series}
        type="bar"
        height={430}
      />

      <div className="d-flex align-items-center mt-2">
        <i class="bi bi-calendar-date-fill pointer fs-20 mb-1"></i>

        <p className="m-0 ms-2 ff-inter fs-14 fw-500">1 March - 5 March</p>
      </div>
    </div>
  );
};
