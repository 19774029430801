import { APIS, BASE_URL } from "../constants/appConstants";
import { fetchPost } from "../utils/fetchPost";

async function checkUser(data) {
  return await fetchPost(BASE_URL + APIS.POST_CHECK_USER, "POST", data);
}

async function fetchUserDetails(data) {
  return await fetchPost(BASE_URL + APIS.POST_USER_DETAILS, "POST", data);
}

async function teacherOnboarding(data) {
  return await fetchPost(
    BASE_URL + APIS.POST_TEACHER_ONBOARDING,
    "POST",
    data
  );
}

const userServices = {
  checkUser,
  fetchUserDetails,
  teacherOnboarding,
};

export default userServices;
