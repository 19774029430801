import { APIS, BASE_URL } from "../constants/appConstants";
import { fetchGet } from "../utils/fetchGet";

async function getAllCircles(data) {
  return await fetchGet(BASE_URL + APIS.GET_ALL_CIRCLES + data?.query);
}

const circleServices = {
  getAllCircles,
};

export default circleServices;
