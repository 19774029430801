import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { AllClassrooms } from "../../components/dashboard/all-classrooms";
import { ClassroomScores } from "../../components/dashboard/classrooms-scores";
import { StrengthAttributes } from "../../components/dashboard/strength-attributes";

export const MainHome = () => {
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      maxWidth: 300,
      border: "1px solid #dadde9",
    },
  }));

  return (
    <div className="row p-4">
      <div className="d-flex justify-content-between">
        <p className="m-0 ff-tw fs-18 fw-600">Classroom: Mayflower</p>

        <HtmlTooltip
          title={
            <React.Fragment>
              <p className="m-0 ff-fw fs-16 fw-500 text-dark p-2">
                Level of interactions is estimated for each classroom each day.
                The estimation is based on a random sampling from the classroom
                video throughout the day and analyzing occurrences of sustained
                one to one interactions using advanced algorithms.
              </p>
            </React.Fragment>
          }
        >
          <button className="btn p-0 m-0">
            <i class="bi bi-info-circle fs-20 pointer"></i>
          </button>
        </HtmlTooltip>
      </div>
      <div className="col-12 mt-4 p-2 px-4 border p-2 rounded">
        <StrengthAttributes />
      </div>
      <div className="col-12 mt-4 p-2 px-4 border p-2 rounded">
        <div>
          <p className="m-0 my-2 ff-tw fw-600 fs-18 text-primary">
            Interaction Score with School average comparison
          </p>
        </div>
        <ClassroomScores />
      </div>
      <div className="row m-0 p-0 my-5">
        <div className="col-12 col-md-6 my-2 p-2 px-4 border rounded">
          <p className="m-0 my-2 ff-tw fw-600 fs-18 text-primary">
            Interaction Scores of all Classrooms
          </p>

          <AllClassrooms />
        </div>
        <div className="col-12 col-md-3 my-2 p-2 px-4 d-flex align-items-center justify-content-center flex-column">
          <p className="m-0 ff-tw fs-24 fw-600"> Ranking Last Week</p>

          <div className="d-flex align-items-center">
            <p className="m-0 ff-tw fs-48 fw-600">3rd</p>
            <i class="bi bi-caret-down-fill fs-64 text-danger"></i>
          </div>
          <p className="m-0 ff-tw fs-18 fw-500 text-center">
            Down from the 2nd position over the last Week
          </p>
        </div>
        <div className="col-12 col-md-3 my-2 p-2 px-4 d-flex align-items-center justify-content-center flex-column">
          <p className="m-0 ff-tw fs-24 fw-600"> Ranking Last Week</p>

          <div className="d-flex align-items-center">
            <p className="m-0 ff-tw fs-48 fw-600">30%</p>
            <i class="bi bi-caret-up-fill fs-64 text-success"></i>
          </div>
          <p className="m-0 ff-tw fs-18 fw-500 text-center">
            7% up from the previous week, 5% higher than you school average
          </p>
        </div>
      </div>
    </div>
  );
};
