import { APIS, BASE_URL } from "../constants/appConstants";
import { fetchPost } from "../utils/fetchPost";

async function getAllClassrooms(data) {
  return await fetchPost(
    BASE_URL + APIS.GET_ALL_CLASSROOMS,
    "POST",
    data
  );
}

const classroomServices = {
  getAllClassrooms,
};

export default classroomServices;
