import axios from "axios";

const fetchGet = async (path = "") => {
  const tokenLSKey = process.env.REACT_APP_LOCAL_STORAGE_KEY;

  const encryptedToken = localStorage.getItem(tokenLSKey);

  return axios.get(path, {
    headers: { Authorization: `Bearer ${encryptedToken}` },
  });
};
export { fetchGet };
