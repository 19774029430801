export const BASE_URL = "https://web.class-proxima.com";

export const APIS = {
  GET_FEED: "/get-feed",
  UPDATE_PARENT_FEED: "/update-parent-feed",
  UPDATE_TEACHERS_FEED: "/update-teachers-feed",
  APPROVE_FEED: "/approve-feed",
  INITIAL_API: "/initial-api",
  ADD_COMMENT: "/add-comment",
  ALL_COMMENTS: "/all-comments",
  GET_FEED_BY_ID: "/get-feed-by-id",
  GET_SUPERADMIN_CONFIG: "/get-superadmin-config",
  LOG_SESSION_START: "/log-session-start",
  LOG_SESSION_END: "/log-session-end",
  GET_USER_USAGE: "/get-user-usage",
  GET_ALL_USER_USAGE: "/get-all-user-usage",
  GET_ALL_COMMENTS: "/get-all-comments",
  GET_ALL_COMMENTS_BY_LIST: "/get-all-comments-by-list",
  UPDATE_SHOW_FEED_TO_PARENT: "/update-show-feed-to-parent",
  UPDATE_ADMIN_SHOW_FEED_TO_PARENT: "/update-admin-show-feed-to-parent",
  DISLIKE_FEED: "/dislike-feed",
  GET_FEED_STATS: "/get-feed-stats",
  GET_ALL_CAMERAS: "/get-all-cameras",
  UPDATE_CAMERA: "/update-camera",
  TOGGLE_CAMERA: "/toggle-camera",
  GET_ALL_SUBS: "/get-all-subs",
  CREATE_PAYMENT_INTENT: "/create-payment-intent",
  SAVE_CC_DETAILS: "/save-cc-details",
  START_SUBSCRIPTION: "/start-subscription",
  CALC_PRICING: "/calc-pricing",
  GET_PUBLISH_KEY: "/get-publish-key",
  GET_SUBS_DATA: "/get-subscription-data",
  CANCEL_SUBS: "/cancel-subscription",
  GET_BACKOFFICE_USER: "/get-backoffice-user",
  CHECK_BACKOFFICE_USER: "/check-backoffice-user",

  GET_ALL_CIRCLES: "/circles/all",
  GET_ALL_MOMENTS: "/moments/all",
  POST_MOMENT_SHARE: "/moments/share",
  POST_MOMENT_LIKE: "/moments/like",
  POST_CHECK_USER: "/user/check",
  POST_USER_DETAILS: "/user/fetch-details",
  POST_TEACHER_ONBOARDING: "/user//teacher-onboarding",
  GET_ALL_SCHOOLS: "/school/all",
  GET_ALL_CLASSROOMS: "/classroom/all",
};

export const FEED_TYPE = {
  feed: "feed",
  intruderAlert: "intruder_alert",
  childLeftBehind: "child_left_behind_alert",
};

export const FEED_TYPE_PARAMS = {
  person_detected: "intruder_alert",
  child_saftey: "child_left_behind_alert",
};

export const FILTERS = [
  { label: "All", action: FEED_TYPE?.feed, href: "all" },
  {
    label: "Person Detected",
    action: FEED_TYPE?.intruderAlert,
    href: "person_detected",
  },
  {
    label: "Child Left Alone",
    action: FEED_TYPE?.childLeftBehind,
    href: "child_left_alone",
  },
];

export const SECTION_TYPES = {
  Moments: "Moments",
  Alerts: "Alerts",
};

export const CURR_LABEL_INDICATOR = {
  feed: "Shareable Moments",
  intruder_alert: "Person Detected",
  child_left_behind_alert: "Child Left alone",
};

export const CURR_LABEL_INDICATOR2 = {
  feed: "Shareable Moments",
  intruder_alert: "person detected",
  child_left_behind_alert: "child left alone",
};

export const SECTIONS = [
  {
    name: "Moments",
    subSections: [{ label: "Shareable Moments", key: "feed" }],
  },

  {
    name: "Alerts",
    subSections: [
      { label: "Person Detected", key: "intruder_alert" },
      { label: "Child Left alone", key: "child_left_behind_alert" },
    ],
  },
];

export const ICONS_MAPPING = {
  child_left_behind_alert: "bi-info-circle-fill",
  intruder_alert: "bi-person",
};

export const LABEL_MAPPING = {
  child_left_behind_alert: "Child Safety Alert",
  intruder_alert: "Person Detection",
};

export const ALERT_SECTIONS = [
  {
    name: "Alerts",
    subSections: [
      {
        showAlerts: true,
        label: "Child Safety Alert",
        key: "child_left_behind_alert",
        iconName: "bi-info-circle-fill",
      },
      {
        showAlerts: true,
        label: "Person Detection",
        key: "intruder_alert",
        iconName: "bi-person",
      },
      {
        showAlerts: false,
        label: "Manage Alert Settings",
        key: null,
        iconName: "bi-gear",
      },
    ],
  },
];

export const SNS_CONFIG = {
  BASE_URL:
    "https://72y6g7sks8.execute-api.us-west-2.amazonaws.com/default/SNS-OTP-Handler",
  API_KEY: "ea420nhirahfa435392dkfgkdhk",
  USER_TYPES: {
    SUBSCRIBER: "subscriber",
    OBSERVER: "observer",
    OBSERVER_ALL: "observer_all",
  },
};

export const findAction = (alertChildSafety, alertPersonDetected) => {
  if (alertChildSafety && alertPersonDetected) return "subscribe_all";
  else if (alertChildSafety) return "subscribe_child";
  else if (alertPersonDetected) return "subscribe_person";
  else return "unsubscribe_all";
};

export const formatOTPVerificationUrl = (
  phone_no,
  school_id,
  firstname,
  lastname,
  action,
  user_type = SNS_CONFIG?.USER_TYPES?.SUBSCRIBER
) => {
  return `${SNS_CONFIG?.BASE_URL}?api_key=${SNS_CONFIG?.API_KEY}&phone_no=${phone_no}&user_type=${user_type}&school_id=${school_id}&action=${action}&firstname=${firstname}&lastname=${lastname}`;
};

export const formatUnsubscribeUserUrl = (user) => {
  return `${SNS_CONFIG?.BASE_URL}?api_key=${SNS_CONFIG?.API_KEY}&phone_no=${user?.phone_no}&user_type=${user?.user_type}&school_id=${user?.school_id}&action=unsubscribe_all&firstname=${user?.firstname}&lastname=${user?.lastname}`;
};

export const RESEND_TIMER = 59;

export const SUBSCRIPTION_STATUS = {
  active: "active",
  trialing: "trialing",
  past_due: "past_due",
  unpaid: "unpaid",
  canceled: "canceled",
  incomplete: "incomplete",
  incomplete_expired: "incomplete_expired",
};
export const PAGES = {
  ALERTS: "alerts",
  START_TRIAL: "subscription",
  PAYMENT: "payment",
};

export const PAGE_TO_RENDER = {
  active: PAGES?.ALERTS,
  trialing: PAGES?.ALERTS,
  past_due: PAGES?.START_TRIAL,
  unpaid: PAGES?.START_TRIAL,
  canceled: PAGES?.START_TRIAL,
  incomplete: PAGES?.START_TRIAL,
  incomplete_expired: PAGES?.START_TRIAL,
  HAVENT_STARTED: PAGES?.START_TRIAL,
};

export const formatPrice = (price) => {
  return `$${price / 100}`;
};

export const sidebarOptions = [
  {
    label: "Moments",
    route: "/moments",
    iconName: "bi-play-circle",
  },
  {
    label: "Statistics",
    route: "/statistics",
    iconName: "bi-house-door",
  },
];

export const demoAccountSidebarOptions = [
  {
    label: "Moments",
    route: "/moments_",
    iconName: "bi-play-circle",
  },
  {
    label: "Statistics",
    route: "/statistics",
    iconName: "bi-house-door",
  },
  {
    label: "Child Safety Alert",
    route: "/alerts?name=child_saftey",
    iconName: "bi-info-circle-fill",
    name: "child_saftey",
  },
  {
    label: "Person Detection",
    route: "/alerts?name=person_detected",
    iconName: "bi-person",
    name: "person_detected",
  },
];

export const demoAccounts = [
  "demo@class-proxima.com",
  "parissa_demo@moments.com",
  "john_demo@moments.com",
];

export const allowedUnverifiedAccounts = [
  ...demoAccounts,
  "school@admin.com",
  "classroom1@gmail.com",
  "hello@generatewhatsnext.com",
  "parissasnider@msn.com",
  "teacher_g@test.com",
];

export const USER_ROLES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  TEACHER: "TEACHER",
  SCHOOL_ADMIN: "SCHOOL_ADMIN",
};
