import { useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import "./video-card-shorts.css";

const VideoCardShorts = ({
  videoRef,
  item,
  height,
  isControls,
}) => {
  const [loading, setLoading] = useState(false);
  const [isFullScreen, setFullScreen] = useState(false);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const vidRef = useRef();
  const handleLoadStart = () => {
    setLoading(true);
  };

  const handleLoadEnd = () => {
    setLoading(false);
  };

  const handleFullScreen = () => {
    const elem = vidRef?.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
    setFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
    setFullScreen(false);
  };

  return (
    <div
      ref={vidRef}
      style={{
        height,
        borderRadius: isTabletOrMobile ? 0 : 10,
      }}
      key={item?._id}
      className="feed-video-wrapper"
    >
      {loading && (
        <div className="d-flex justify-content-center align-items-center h-100 w-100 skeleton-loader"></div>
      )}

      <video
        ref={videoRef}
        autoPlay
        muted="muted"
        controls={isControls}
        onLoadStart={handleLoadStart}
        onLoadedData={handleLoadEnd}
        controlsList="nodownload"
        className="feed-video"
        playsInline
        loop
        style={{
          background: "transparent",
          borderBottom: "1px solid transparent",
          objectFit: isTabletOrMobile ? "fill" : "cover",
        }}
      >
        <source src={item?.moment_url} type="video/mp4" />
      </video>

      <div className="position-absolute bottom-0 py-3 px-2 pb-3">
        <p className="ff-inter text-white fw-700 fs-18 m-0 text-blur-bg mb-1">
         {item?.school_id?.name}
        </p>
        <p className="ff-inter text-white fw-500 fs-14 m-0 text-blur-bg">
          {item?.createdAtStr}
        </p>
      </div>

      <div
        className={`w-100 h-100 d-flex d-md-none position-absolute p-2 d-flex justify-content-end video-controls-div`}
      >
        <div
          className="d-flex border border-dark align-items-center rounded-circle justify-content-center pointer"
          style={{
            background: "#00000050",
            width: isTabletOrMobile ? 30 : 40,
            height: isTabletOrMobile ? 30 : 40,
          }}
          onClick={isFullScreen ? handleCloseFullScreen : handleFullScreen}
        >
          <i
            className={`bi bi-fullscreen${
              isFullScreen ? "-exit" : ""
            } fs-16 fw-700 text-white`}
          ></i>
        </div>
      </div>
    </div>
  );
};

export default VideoCardShorts;
