import { Avatar } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getDPInitials, useAuth } from "../../utils/appUtils";
import { deepPurple } from "@mui/material/colors";
export const OnboardingHeader = () => {
  const { userDbData, user } = useContext(AppContext);
  const { logoutUser } = useAuth();

  const { height } = useWindowDimensions();
  const HEADER_HEIGHT = height * 0.08;
  return (
    <div
      className="border-bottom d-flex justify-content-between align-items-center text-white"
      style={{
        height: HEADER_HEIGHT,
      }}
    >
      <div className="d-flex align-items-center pointer" onClick={logoutUser}>
        <i class="bi bi-arrow-left"></i>
        <p className="m-0 ff-tw fw-600 fs-20 ms-2"> Logout </p>
      </div>
      <div className="d-flex align-items-center">
        <Avatar className="fs-14" sx={{ bgcolor: deepPurple[500] }}>
          {getDPInitials(userDbData?.name || user?.displayName)}
        </Avatar>
      </div>
    </div>
  );
};
